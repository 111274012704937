<template>
	<div>
		<!-- Page Header -->
		<div class="d-flex justify-space-between align-center mb-3">
			<h3 class="text-h4 font-weight-light">Orders</h3>

			<div class="d-inline-flex align-center">
				<div v-if="search['show']" class="d-inline-block mr-3">
					<v-text-field class="fsk-v-input" v-model="search['input']" name="search" type="search" dense hide-details="auto" prepend-inner-icon="mdi-magnify" outlined clearable></v-text-field>
				</div>
				<v-btn v-if="!search['show']" icon :small="!$vuetify.breakpoint.lgAndUp" color="grey" @click="search['show'] = true"><v-icon>mdi-magnify</v-icon></v-btn>
				<v-btn icon :small="!$vuetify.breakpoint.lgAndUp" color="grey" @click.stop="showForm"><v-icon>mdi-plus-circle-outline</v-icon></v-btn>
				<v-btn icon :small="!$vuetify.breakpoint.lgAndUp" color="grey" @click="resetTable()"><v-icon>mdi-cached</v-icon></v-btn>
			</div>
		</div>

		<!-- Page Body -->
		<div>
			<v-card :loading="loading" v-if="!reset">
				<v-card-text>
					<v-data-table
						class="fsk-v-data-table"
						:headers="headers"
						:items="table"
						:search="search['input']"
					>
						<template v-slot:[`item.lead_name`]="{ item }">
							<div class="py-3">
								<div>{{ item['lead_name'] }}</div>
								<div class="caption">{{ item['lead_company'] }}</div>
							</div>
						</template>
						<template v-slot:[`item.lead_phone`]="{ item }">
							<div>
								<div>{{ item['lead_phone'] }}</div>
								<div class="caption">{{ item['lead_email'] }}</div>
							</div>
						</template>
						<template v-slot:[`item.status`]="{ item }">
							<div class="text-center">
								<v-chip :color="getStatusColor(item.status)" x-small outlined>{{ item['status'] }}</v-chip>
							</div>
						</template>
						<template v-slot:[`item.date`]="{ item }">
							<div>{{ $moment.utc(item['date']).format('DD MMM YYYY') }}</div>
						</template>
						<template v-slot:[`item.actions`]="{ item }">
							<div class="v-data-table-actions">
								<v-menu bottom left>
									<template v-slot:activator="{ on, attrs }">
									<v-btn icon v-bind="attrs" v-on="on">
										<v-icon>mdi-dots-horizontal</v-icon>
									</v-btn>
									</template>

									<v-list>
									<v-list-item dense @click="editItem(item)">
										<v-list-item-icon class="mr-0"><v-icon small>mdi-file</v-icon></v-list-item-icon>
										<v-list-item-content>
											<v-list-item-title>View / Edit</v-list-item-title>
										</v-list-item-content>
									</v-list-item>
									<v-list-item dense @click="updateStatus(item)">
										<v-list-item-icon class="mr-0"><v-icon small>mdi-tag-multiple</v-icon></v-list-item-icon>
										<v-list-item-content>
											<v-list-item-title>Update Status</v-list-item-title>
										</v-list-item-content>
									</v-list-item>
									<v-list-item dense>
										<v-list-item-icon class="mr-0"><v-icon small>mdi-checkbox-multiple-outline</v-icon></v-list-item-icon>
										<v-list-item-content>
											<v-list-item-title>Manage Tasks</v-list-item-title>
										</v-list-item-content>
									</v-list-item>
									<v-list-item dense>
										<v-list-item-icon class="mr-0"><v-icon small>mdi-comment-processing</v-icon></v-list-item-icon>
										<v-list-item-content>
											<v-list-item-title>Comments</v-list-item-title>
										</v-list-item-content>
									</v-list-item>
									<v-list-item dense>
										<v-list-item-icon class="mr-0"><v-icon small>mdi-receipt-text-check</v-icon></v-list-item-icon>
										<v-list-item-content>
											<v-list-item-title>Generate Bill</v-list-item-title>
										</v-list-item-content>
									</v-list-item>
									<v-list-item dense @click="deleteItem(item)">
										<v-list-item-icon class="mr-0"><v-icon small>mdi-delete</v-icon></v-list-item-icon>
										<v-list-item-content>
											<v-list-item-title>Delete</v-list-item-title>
										</v-list-item-content>
									</v-list-item>
									</v-list>
								</v-menu>
							</div>
						</template>
					</v-data-table>
				</v-card-text>
			</v-card>
		</div>

		<!-- Create Dialog -->
		<v-dialog
			v-model="form['show']"
			persistent
			scrollable
			content-class="fsk-v-create-dialog"
		>
			<v-card :loading="form['loading']" :disabled="form['loading']">
				<v-card-title>
					<span v-if="form['create']" class="headline">New Order</span>
					<span v-else class="headline">Edit Order</span>
				</v-card-title>
				<v-card-text style="max-height: 80vh;">
					<v-row>
						<v-col cols="12" md="6">
							<v-dialog ref="dialogDate" v-model="modal['date']" :return-value.sync="form['data']['date']" persistent width="290px">
								<template v-slot:activator="{ on, attrs }">
								<v-text-field :value="$moment.utc(form['data']['date']).format('DD MMM YYYY')" class="fsk-v-input" label="Date *" name="date" readonly outlined dense hide-details="auto" v-bind="attrs" v-on="on"></v-text-field>
								</template>
								<v-date-picker v-model="form['data']['date']" scrollable @input="$refs.dialogDate.save(form['data']['date'])">
								</v-date-picker>
							</v-dialog>
						</v-col>
						<v-col cols="12" md="6">
							<v-select v-model="form['data']['lead']" class="fsk-v-input" :items="['Charles Cooper - Hangar Pvt. Ltd', 'Stephen Clark - Smart Ltd', 'Russell Lee - Rus Corp', 'Rachel Wood - CC Corp.', 'Joel Hill - WebTech', 'Kevin Harris - Shazar Tech']" label="Lead *" name="lead" outlined dense hide-details="auto"></v-select>
						</v-col>
						<v-col cols="12"><hr></v-col>
						<v-col cols="12" class="py-0">
							<v-row>
								<v-col cols="12" md="10" class="py-0 py-md-3">
									<v-row>
										<v-col cols="12" md="6" class="py-md-0">
											<v-select v-model="form['data']['rows'][0]['inventory']" class="fsk-v-input" :items="['Website', 'Domain + Hosting', 'Instagram Ads']" label="Products / Services *" name="inventory" outlined dense hide-details="auto"></v-select>
										</v-col>
										<v-col cols="6" md="3" class="py-md-0">
											<v-text-field v-model="form['data']['rows'][0]['amount']" class="fsk-v-input" label="Amount *" name="amount" type="text" outlined dense hide-details="auto"></v-text-field>
										</v-col>
										<v-col cols="6" md="3" class="py-md-0">
											<v-text-field v-model="form['data']['rows'][0]['quantity']" class="fsk-v-input" label="Quantity *" name="quantity" type="text" outlined dense hide-details="auto"></v-text-field>
										</v-col>
									</v-row>
								</v-col>
								<v-col cols="12" md="2" class="d-flex align-center">
									<v-btn block depressed>Del</v-btn>
								</v-col>
								<v-col cols="12" md="10" class="py-0 py-md-3">
									<v-row>
										<v-col cols="12" md="6" class="py-md-0">
											<v-select v-model="form['data']['rows'][1]['inventory']" class="fsk-v-input" :items="['Website', 'Domain + Hosting', 'Instagram Ads']" label="Products / Services" name="inventory" outlined dense hide-details="auto"></v-select>
										</v-col>
										<v-col cols="6" md="3" class="py-md-0">
											<v-text-field v-model="form['data']['rows'][1]['amount']" class="fsk-v-input" label="Amount" name="amount" type="text" outlined dense hide-details="auto"></v-text-field>
										</v-col>
										<v-col cols="6" md="3" class="py-md-0">
											<v-text-field v-model="form['data']['rows'][1]['quantity']" class="fsk-v-input" label="Quantity" name="quantity" type="text" outlined dense hide-details="auto"></v-text-field>
										</v-col>
									</v-row>
								</v-col>
								<v-col cols="12" md="2" class="d-flex align-center">
									<v-btn block depressed>Add</v-btn>
								</v-col>
							</v-row>
						</v-col>
						<v-col cols="12"><hr></v-col>
						<v-col cols="12">
							<v-row>
								<v-col cols="6" md="3" class="py-md-0">
									<v-text-field v-model="form['data']['sub_total']" class="fsk-v-input" label="Sub Total *" name="sub_total" type="text" readonly outlined dense hide-details="auto"></v-text-field>
								</v-col>
								<v-col cols="6" md="3" class="py-md-0">
									<v-text-field v-model="form['data']['tax']" class="fsk-v-input" label="Tax" name="tax" type="text" outlined dense hide-details="auto"></v-text-field>
								</v-col>
								<v-col cols="6" md="3" class="py-md-0">
									<v-text-field v-model="form['data']['discount']" class="fsk-v-input" label="Discount" name="discount" type="text" outlined dense hide-details="auto"></v-text-field>
								</v-col>
								<v-col cols="6" md="3" class="py-md-0">
									<v-text-field v-model="form['data']['total']" class="fsk-v-input" label="Total *" name="total" type="text" readonly outlined dense hide-details="auto"></v-text-field>
								</v-col>
							</v-row>
						</v-col>
					</v-row>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions class="py-4 px-6">
					<v-spacer></v-spacer>
					<v-btn color="grey" text @click="hideForm()">Close</v-btn>
					<v-btn color="primary" text @click="submitForm()">Save</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- Status Dialog -->
		<v-dialog
			v-model="tag['show']"
			persistent
			max-width="400"
		>
		<v-card :loading="tag['loading']" :disabled="tag['loading']">
			<v-card-title class="headline">Update Status</v-card-title>

			<v-card-text style="max-height: 80vh;">
				<v-row>
					<v-col cols="12">
						<v-select v-model="tag['status']" class="fsk-v-input" :items="['In Process', 'Overdue', 'Complete', 'Pending']" label="Status *" name="status" outlined dense :color="getStatusColor(tag['status'])" hide-details="auto"></v-select>
					</v-col>
				</v-row>
			</v-card-text>

			<v-divider></v-divider>
			<v-card-actions class="py-4 px-6">
				<v-spacer></v-spacer>
				<v-btn color="grey" text @click="hideStatus()">Close</v-btn>
				<v-btn color="primary" text @click="confirmStatus()">Save</v-btn>
			</v-card-actions>
		</v-card>
		</v-dialog>

		<!-- Delete Dialog -->
		<v-dialog
			v-model="del['show']"
			persistent
			max-width="400"
		>
		<v-card :loading="del['loading']" :disabled="del['loading']">
			<v-card-title class="headline">Delete Order</v-card-title>

			<v-card-text>Please confirm the deletion of order <strong>{{ del['subject'] }}</strong></v-card-text>

			<v-divider></v-divider>
			<v-card-actions class="py-4 px-6">
				<v-spacer></v-spacer>
				<v-btn color="grey" text @click="hideDelete()">Close</v-btn>
				<v-btn color="primary" text @click="confirmDelete()">Confirm</v-btn>
			</v-card-actions>
		</v-card>
		</v-dialog>
	</div>
</template>

<script>
	export default {
		data: () => ({
			modal: {
				date: false
			},
			search: {
				show: false,
				input: ''
			},
			loading: false,
			reset: false,
			headers: [
				{
					text: 'Name | Company',
					align: 'start',
					value: 'lead_name',
				},
				{
					text: 'Date',
					value: 'date',
				},
				{
					text: 'Contact',
					value: 'lead_phone',
				},
				{
					text: 'Order No.',
					value: 'order_no',
				},
				{
					text: 'Product / Service',
					value: 'rows[0].inventory',
				},
				{
					text: 'Total',
					value: 'total',
				},
				{
					text: 'Status',
					value: 'status',
					align: 'center',
				},
				{ text: 'Actions', align: 'end', value: 'actions', sortable: false },
			],
			table: [],
			form: {
				create: true,
				show: false,
				loading: false,
				data: {
					id: '',
					date: '',
					order_no: '',
					lead: '',
					lead_name: '',
					lead_company: '',
					lead_phone: '',
					lead_email: '',
					rows: [
						{
							inventory: '',
							amount: '',
							quantity: '',
						},
						{
							inventory: '',
							amount: '',
							quantity: '',
						}
					],
					sub_total: '',
					tax: '',
					discount: '',
					total: '',
					status: '',
				}
			},
			tag: {
				show: false,
				loading: false,
				id: '',
				status: ''
			},
			del: {
				show: false,
				loading: false,
				id: '',
				subject: ''
			}
		}),

		created() {
			this.initialize();
		},

		methods: {
			initialize () {
				this.table = [
					{
						id: 1,
						order_no: 'FSK0001',
						lead_name: 'Charles Cooper',
						lead_company: 'Hangar Pvt. Ltd',
						lead_phone: '9231457895',
						lead_email: 'charles@hangar.com',
						rows: [
							{
								inventory: 'Website',
								amount: '45,000',
								quantity: '1',
							}
						],
						sub_total: '45,000',
						tax: '',
						discount: '',
						total: '45,000',
						status: 'Complete',
						date: new Date()
					},
					{
						id: 2,
						order_no: 'FSK0002',
						lead_name: 'Stephen Clark',
						lead_company: 'Smart Ltd',
						lead_phone: '9231457895',
						lead_email: 'stephen@smart.com',
						rows: [
							{
								inventory: 'Domain + Hosting',
								amount: '1,65,000',
								quantity: '1',
							}
						],
						sub_total: '1,65,000',
						tax: '',
						discount: '',
						total: '1,65,000',
						status: 'Overdue',
						date: new Date(Date.now() - 86400000)
					},
					{
						id: 3,
						order_no: 'FSK0003',
						lead_name: 'Russell Lee',
						lead_company: 'Rus Corp',
						lead_phone: '9231451423',
						lead_email: 'russell@rcorp.com',
						rows: [
							{
								inventory: 'Instagram Ads',
								amount: '97,000',
								quantity: '1',
							}
						],
						sub_total: '97,000',
						tax: '',
						discount: '',
						total: '97,000',
						status: 'Complete',
						date: new Date(Date.now() - 3888000000)
					},
					{
						id: 4,
						order_no: 'FSK0004',
						lead_name: 'Rachel Wood',
						lead_company: 'CC Corp.',
						lead_phone: '9212678992',
						lead_email: 'rachel@ccc.com',
						rows: [
							{
								inventory: 'Website',
								amount: '2,50,000',
								quantity: '1',
							}
						],
						sub_total: '2,50,000',
						tax: '',
						discount: '',
						total: '2,50,000',
						status: 'Pending',
						date: new Date(Date.now() - 6480000000)
					},
					{
						id: 5,
						order_no: 'FSK0005',
						lead_name: 'Joel Hill',
						lead_company: 'WebTech',
						lead_phone: '9212678992',
						lead_email: 'joelbhill@rhyta.com',
						rows: [
							{
								inventory: 'Website',
								amount: '2,45,000',
								quantity: '1',
							}
						],
						sub_total: '2,45,000',
						tax: '',
						discount: '',
						total: '2,45,000',
						status: 'In Process',
						date: new Date(Date.now() - 7344000000)
					},
				]
			},
			resetTable() {
				this.loading = true;

				setTimeout(() => {
					this.loading = false;
					this.reset = true;
					this.reset = false;
				}, 3000);
			},
			getStatusColor (status) {
				switch (status) {
					case 'In Process':
						return 'amber'
					
					case 'Overdue':
						return 'blue'
					
					case 'Complete':
						return 'success'
					
					case 'Pending':
						return 'red'
			
					default:
						return 'grey'
				}
			},
			editItem(item) {
				// Assign form data
				this.form['data']['id'] = item['id'];
				this.form['data']['date'] = item['date'];
				this.form['data']['order_no'] = item['order_no'];
				this.form['data']['lead'] = `${item['lead_name']} - ${item['lead_company']}`;
				this.form['data']['lead_name'] = item['lead'];
				this.form['data']['lead_company'] = item['lead_company'];
				this.form['data']['lead_phone'] = item['lead_phone'];
				this.form['data']['lead_email'] = item['lead_email'];
				this.form['data']['rows'][0]['inventory'] = item['rows'][0]['inventory'];
				this.form['data']['rows'][0]['amount'] = item['rows'][0]['amount'];
				this.form['data']['rows'][0]['quantity'] = item['rows'][0]['quantity'];
				this.form['data']['sub_total'] = item['sub_total'];
				this.form['data']['tax'] = item['tax'];
				this.form['data']['discount'] = item['discount'];
				this.form['data']['total'] = item['total'];
				this.form['data']['status'] = item['status'];

				// Show dialog
				this.showForm('edit');
			},
			updateStatus(item) {
				// Assign data
				this.tag['id'] = item['id'];
				this.tag['status'] = item['status'];

				// Show dialog
				this.tag['show'] = true;
			},
			hideStatus() {
				// Reset data
				this.tag['id'] = '';
				this.tag['status'] = '';

				// Unset loading
				this.tag['loading'] = false;

				// Hide dialog
				this.tag['show'] = false;
			},
			confirmStatus() {
				// TODO: Add update status API
				console.log(this.tag['id']);

				// Set loading
				this.tag['loading'] = true;

				setTimeout(() => {
					// Hide dialog
					this.hideStatus();
				}, 3000);
			},
			deleteItem(item) {
				// Assign data
				this.del['id'] = item['id'];
				this.del['subject'] = item['subject'];

				// Show dialog
				this.del['show'] = true;
			},
			hideDelete() {
				// Reset data
				this.del['id'] = '';
				this.del['subject'] = '';

				// Unset loading
				this.del['loading'] = false;

				// Hide dialog
				this.del['show'] = false;
			},
			confirmDelete() {
				// TODO: Add delete order API
				console.log(this.del['id']);

				// Set loading
				this.del['loading'] = true;

				setTimeout(() => {
					// Hide dialog
					this.hideDelete();
				}, 3000);
			},
			resetForm() {
				this.form['data']['id'] = '';
				this.form['data']['date'] = '';
				this.form['data']['order_no'] = '';
				this.form['data']['lead'] = '';
				this.form['data']['lead_name'] = '';
				this.form['data']['lead_company'] = '';
				this.form['data']['lead_phone'] = '';
				this.form['data']['lead_email'] = '';
				this.form['data']['rows'][0]['inventory'] = '';
				this.form['data']['rows'][0]['amount'] = '';
				this.form['data']['rows'][0]['quantity'] = '';
				this.form['data']['sub_total'] = '';
				this.form['data']['tax'] = '';
				this.form['data']['discount'] = '';
				this.form['data']['total'] = '';
				this.form['data']['status'] = '';
			},
			showForm(type = 'create') {
				if (type == 'edit') {
					this.form['create'] = false;
				}
				else {
					this.form['create'] = true;

					// Prepopulate data
					this.form['data']['order_no'] = 'FSK0123';
					this.form['data']['date'] = this.$moment.utc(new Date()).format('YYYY-MM-DD');
				}

				// Show Dialog
				this.form['show'] = true;
			},
			hideForm() {
				// Hide dialog
				this.form['show'] = false;

				// Unset loading
				this.form['loading'] = false;

				// Clear form
				this.resetForm();
			},
			submitForm() {
				if (this.form['create']) {
					// TODO: Add create order API
					console.log(this.form['data']);

					// Set loading
					this.form['loading'] = true;
				}
				else {
					// TODO: Add edit order API
					console.log(this.form['data']);

					// Set loading
					this.form['loading'] = true;
				}
				setTimeout(() => {
					// Hide dialog
					this.hideForm();
				}, 3000);
			}
		}
	}
</script>
